<template>
  <div v-if="renderComponent">
    <b-card
      class="mb-0 shadow-none"
      :title="category.category"
      v-for="(category, index) in filters"
      :key="index"
    >
      <b-row v-for="(field, idx) in category.fields" :key="idx">
        <b-col cols="12">
          <b-form-group :label="field.label" :label-for="`extract-field-${field.key}`">
            <v-select
              v-if="field.type === 'select'"
              :id="`field-${field.key}`"
              v-model="filterValues[field.key]"
              :options="field.options"
            />

            <b-form-input
              v-else-if="field.type === 'text'"
              :id="`field-${field.key}`"
              v-model="filterValues[field.key]"
              class="form-control"
            ></b-form-input>

            <flat-pickr
              v-else
              :id="`field-${field.key}`"
              v-model="filterValues[field.key]"
              class="form-control"
              :config="field.options"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-row class="px-2">
      <b-col cols="12" class="d-flex flex-row justify-content-start">
        <b-button variant="outline-primary" @click="clearFilters">
          <feather-icon icon="RotateCcwIcon" /> Limpar filtros</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BButton, BFormGroup, BFormInput, BRow, BCol } from 'bootstrap-vue'
import Filters from '../components/Filters.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  name: 'Filters',
  props: {
    customFilters: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    Filters,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      renderComponent: true,
      filterValues: {},
      statusOptions: [],
    }
  },
  mounted() {
    if (this.filters) {
      const totalFilters = this.filters?.length || 1
      this.statusOptions = this.filters[totalFilters - 1].fields.filter(async (field, index) => {
        if (!!field.action) {
          try {
            const data = await this.$store.dispatch(field.action)

            this.$nextTick(() => {
              field.options = data.map((item) => {
                return {
                  label: item.nome,
                  value: item.id,
                }
              })
            })
          } catch (error) {
            this.$nextTick(() => (field.options = []))
          }
        }
      })
      this.forceRerender()
    }
  },
  methods: {
    clearFilters() {
      this.filterValues = {}
    },
    async forceRerender() {
      this.renderComponent = false
      await this.$nextTick()
      this.renderComponent = true
    },
  },
  computed: {
    filters() {
      return this.customFilters || this.$$attrs.filters || this.$route.meta.indexObject?.filters
    },
  },
  watch: {
    filterValues: {
      handler() {
        let filters = {}

        Object.entries(this.filterValues).filter((obj) => {
          let key = obj[0]
          let value = typeof obj[1] === 'string' ? obj[1] : obj[1]?.value || ''

          if (typeof value === 'string' && value.match(/[0-9] to [0-9]/g))
            value = value.split(' até ')
          if (value) filters[key] = value
        })

        this.$emit('updateFilterValues', filters)
      },
      deep: true,
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
