<template>
  <b-card :no-body="noBody">
    <div class="mb-2">
      <slot name="table-header">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <div>
            <h4>
              {{ datatableTitleProps }}
            </h4>
            <h4 class="mb-0" v-if="$route.meta.indexObject?.datatableTitle">
              {{ $route.meta.indexObject.datatableTitle}}
            </h4>
            <slot v-else name="table-caption" />
          </div>
          <div class="d-flex flex-row align-items-center justify-content-end">
            <slot name="table-header-actions"></slot>
            <div name="table-header-filter" v-if="canShowFiltersButton" class="ml-1">
              <feather-icon
                icon="FilterIcon"
                size="24"
                @click="showFiltersButton = true"
                class="cursor-pointer text-secondary"
              />
            </div>
          </div>
        </div>
      </slot>
    </div>

    <b-table
      id="my-table"
      ref="ccDatatable"
      outlined
      responsive
      :selectable="enableSelectableDatatable"
      class="position-relative text-center min-height-table"
      :current-page="currentPage"
      :items="items"
      :data="data"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      @row-selected="onRowSelected"
      @head-clicked="onHeadClicked"
    >
      <template #cell(editAction)="data">
        <router-link
          tag="span"
          :to="{
            name: showRouteName,
            params: { id: data.item.id },
          }"
          class="d-flex align-items-center justify-content-center text-center"
        >
          <feather-icon
            icon="EditIcon"
            class="cursor-pointer"
            size="20"
            v-b-tooltip.hover
            title="Editar"
          />
        </router-link>
      </template>

      <template #cell()="data">
        <div v-html="data.unformatted" v-if="data.item.isCustomLastRow"></div>
        <span v-html="data.value" v-else />
      </template>

      <template #cell(import_exception_status)="data">
        <div
          class="cursor-pointer"
          @click="showExeptionFileImportEventImportErrors(data.item)"
        >
          <span v-html="exeptionFileImportEventStatusBadge(data.item)"  v-b-tooltip.hover :title="`${data.item.import_errors.join(';').substring(0,50)}...`"></span>
        </div>
      </template>

      <template #cell(select)="data" v-if="enableSelectableDatatable">
        <div>
          <b-form-checkbox v-model="data.rowSelected" @change="selectRow(data, $event)" />
        </div>
      </template>

      <template #cell(copy)="data">
        <div class="d-flex">
          <div :id="`copyTooltip-${data.item.id}`" class="cursor-pointer" @click="copy(data.item)" v-if="data.item.chave_nfe != null">
            <feather-icon icon="CopyIcon" size="20" class="mr-1 text-primary" />
            <b-tooltip
              :ref="`tooltip-${data.item.id}`"
              triggers="click"
              :target="`copyTooltip-${data.item.id}`"
              title="Copiado!"
              variant="primary"
              :delay="{ show: 100, hide: 500 }"
            />
          </div>
          <div :id="`featherIcon-${data.item.id}`">
            <a :href="data.item?.lastro_url" v-if="data.item?.lastro_url" target="_blank">
              <feather-icon
                icon="DownloadIcon"
                size="20"
                class="text-primary cursor-pointer"
              />
            </a>
            <feather-icon v-else icon="AlertCircleIcon" size="20" class="text-primary opacity-05" />
            <b-tooltip
              v-if="!data.item?.lastro_url"
              :ref="`tooltip-${data.item.id}`"
              triggers="hover"
              :target="`featherIcon-${data.item.id}`"
              title="Este título não possui lastro cadastrado."
              variant="primary"
              :delay="{ show: 100, hide: 500 }"
            />
          </div>
        </div>
      </template>

      <template #cell(titleActions)="data">
        <div v-if="data.item.isCustomLastRow"></div>
        <div class="d-flex align-items-center" v-else>
          <div :id="`copyTooltip-${data.item.id}`" class="cursor-pointer" @click="copy(data.item)">
            <feather-icon icon="CopyIcon" size="20" class="text-primary" />
            <b-tooltip
              :ref="`tooltip-${data.item.id}`"
              triggers="click"
              :target="`copyTooltip-${data.item.id}`"
              title="Copiado!"
              variant="primary"
              :delay="{ show: 20, hide: 500 }"
            />
          </div>
          <div :id="`featherIcon-${data.item.id}`" @click="copy(data.item)">
            <feather-icon
              v-if="data.item?.lastro_url"
              @click="directDownload(data.item?.lastro_url)"
              icon="DownloadIcon"
              size="20"
              class="text-primary mx-2 cursor-pointer"
            />
            <feather-icon
              v-else
              icon="AlertCircleIcon"
              size="20"
              class="text-primary mx-2 opacity-05"
            />
            <b-tooltip
              v-if="!data.item?.lastro_url"
              :ref="`tooltip-${data.item.id}`"
              triggers="hover"
              :target="`featherIcon-${data.item.id}`"
              title="Este título não possui lastro cadastrado."
              variant="primary"
              :delay="{ show: 20, hide: 500 }"
            />
          </div>

          <b-dropdown
            block
            variant="flat-primary"
            no-caret
            v-if="$store.state.auth.userData.type === 'Admin::User'"
          >
            <template #button-content>
              <div>
                <feather-icon icon="MoreVerticalIcon" size="20" class="text-primary">
                </feather-icon>
              </div>
            </template>
            <b-dropdown-item @click="rowAction('pay', data.item)">
              Marcar como pago
            </b-dropdown-item>
            <b-dropdown-item @click="rowAction('cancel', data.item)"> Cancelar </b-dropdown-item>
            <b-dropdown-item @click="rowAction('updateDeadline', data.item)">
              Alongar prazo
            </b-dropdown-item>
            <b-dropdown-item @click="rowAction('decreaseValue', data.item)">
              Redução de valor
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

      <template #cell(inviteActions)="{ item, field }">
        <div class="d-flex align-items-center">
          <div
            :id="`copyUrlTooltip-${item.id}`"
            class="cursor-pointer"
            @click="copyInviteLink(item)"
          >
            <feather-icon icon="CopyIcon" size="20" class="text-warning" />
            <b-tooltip
              :ref="`urlTooltip-${item.id}`"
              triggers="click"
              :target="`copyUrlTooltip-${item.id}`"
              title="Copiado!"
              variant="warning"
              :delay="{ show: 20, hide: 500 }"
            />
          </div>
          <feather-icon
            icon="EditIcon"
            size="20"
            class="text-warning mx-2 cursor-pointer"
            @click="field.editAction(item)"
          />
          <feather-icon
            icon="TrashIcon"
            size="20"
            class="text-warning cursor-pointer"
            @click="field.action(item)"
          />
        </div>
      </template>

      <template #cell(userActions)="{ item, field }">
        <div class="d-flex align-items-center">
          <feather-icon
            icon="EditIcon"
            size="20"
            class="text-warning mx-2 cursor-pointer"
            @click="field.editAction(item)"
          />
          <feather-icon
            v-if="item.status == 'ativo'"
            icon="TrashIcon"
            size="20"
            class="text-warning cursor-pointer"
            @click="field.action(item)"
          />
          <feather-icon
            v-if="item.status == 'bloqueado'"
            icon="RefreshCwIcon"
            size="20"
            class="text-warning cursor-pointer"
            @click="field.actionUnblock(item)"
          />
        </div>
      </template>

      <template #cell(sacado)="data">
        <div :id="`tooltip-sacado-${data.item.id}`" class="d-flex flex-column">
          <span class="text-primary font-weight-bolder underline text-nowrap">
            {{ truncateWithEllipsis(data.item.sacado?.nome, 'end', 12) }}
          </span>
        </div>
        <b-tooltip :target="`tooltip-sacado-${data.item.id}`" placement="bottom">
          <span class="font-weight-bold">{{ data.item.sacado?.nome }}</span>
          <br />
          <span class="text-muted">{{ data.item.sacado?.documento }}</span>
        </b-tooltip>
      </template>

      <template #cell(titulo)="data">
        <div :id="`tooltip-titulo-${data.item.id}`" class="d-flex flex-column">
          <span class="text-primary font-weight-bolder underline text-nowrap">
            {{ data.item.titulo?.numero}}
          </span>
        </div>
        <b-tooltip :target="`tooltip-titulo-${data.item.id}`" placement="bottom">
          <span class="text-muted">Remessa: {{ data.item.titulo?.numero_remessa }}</span>
          <br />
          <span class="text-muted">Data de Aquisição: {{ data.item.titulo?.data_aquisicao | dateFormat}}</span>
          <br />
          <span class="text-muted">Data de Vencimento: {{ data.item.titulo?.data_vencimento | dateFormat}}</span>
          <br />
          <span class="text-muted">Data de Liquidação: {{ data.item.titulo?.data_liquidacao | dateFormat }}</span>

          <br />
          <span class="text-muted">Valor Face: {{ data.item.titulo?.valor_face | moneyFormat }}</span>

          <br />
          <span class="text-muted">Valor Operado: {{ data.item.titulo?.valor_operado | moneyFormat }}</span>

          <br />
          <span class="text-muted">Valor Liquidação: {{ data.item.titulo?.valor_liquidacao | moneyFormat}}</span>

        </b-tooltip>
      </template>

      <template #cell(cedente)="data">
        <div :id="`tooltip-cedente-${data.item.id}`" class="d-flex flex-column">
          <span class="text-primary font-weight-bolder underline text-nowrap">
            {{ truncateWithEllipsis(data.item.cedente?.nome, 'end', 20) }}
          </span>
        </div>
        <b-tooltip :target="`tooltip-cedente-${data.item.id}`" placement="bottom">
          <span class="font-weight-bold">{{ data.item.cedente?.nome }}</span>
          <br />
          <span class="text-muted">{{ data.item.cedente?.cnpj }}</span>
        </b-tooltip>
      </template>

      <template #cell(status_importacao)="data">
        <div
          v-if="data.item.status_importacao === 'rejeitado'"
          class="d-flex align-items-center justify-content-center text-center text-nowrap"
        >
          <span
            v-html="data.value"
            class="d-flex align-items-center justify-content-center text-center text-nowrap mr-50"
          ></span>
          <feather-icon
            icon="AlertCircleIcon"
            class="text-danger"
            size="22"
            v-b-tooltip.hover
            :title="data.item.motivo_rejeicao"
          />
        </div>
        <span
          v-else
          v-html="data.value"
          class="d-flex align-items-center justify-content-center text-center text-nowrap"
        ></span>
      </template>

      <template #cell(status_liquidacao)="data">
        <span
          v-html="data.value"
          class="d-flex align-items-center justify-content-center text-center text-nowrap"
        ></span>
      </template>

      <template #cell(baixaActions)="data">
        <div class="d-flex align-items-center">
          <b-dropdown
            block
            variant="flat-primary"
            no-caret
          >
            <template #button-content>
              <div>
                <feather-icon icon="MoreVerticalIcon" size="20" class="text-primary">
                </feather-icon>
              </div>
            </template>
            <b-dropdown-item v-bind:key="'cancel'+data.item.id" @click="rowAction('setTipoBaixaCancelamento', data.item)">Cancelamento</b-dropdown-item>
            <b-dropdown-item v-bind:key="'liquidacao'+data.item.id" @click="rowAction('setTipoBaixaLiquidacao', data.item)">Liquidação</b-dropdown-item>
            <b-dropdown-item v-bind:key="'recompra'+data.item.id" @click="rowAction('setTipoBaixaRecompra', data.item)">Recompra</b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

      <template #cell(ocorrencia_info)="data">
        <div :id="`featherIcon-${data.item.id}`">
          <feather-icon icon="AlertCircleIcon" size="20" class="text-primary" />
          <b-tooltip
            v-if="data.item.tipo === 'devolucao_parcial'"
            :ref="`tooltip-${data.item.id}`"
            triggers="hover"
            :target="`featherIcon-${data.item.id}`"
            :title="`Alterado valor face de ${valorFace(data.item.data)} para ${moneyFormatDatatable(data.item.data.updated_redeemed_amount)}`"
            variant="primary"
            :delay="{ show: 100, hide: 500 }"
          />
          <b-tooltip
            v-if="data.item.tipo === 'prorrogacao'"
            :ref="`tooltip-${data.item.id}`"
            triggers="hover"
            :target="`featherIcon-${data.item.id}`"
            :title="`Prorrogado de ${brazilianFormattedDate(data.item.data.reference_date)} para ${brazilianFormattedDate(data.item.data.updated_due_date)}.`"
            variant="primary"
            :delay="{ show: 100, hide: 500 }"
          />
        </div>
      </template>

      <template #cell(more)="data">
        <div class="d-flex align-items-center justify-content-center text-center text-nowrap">
          <b-dropdown block variant="flat-primary" no-caret class="">
            <template #button-content>
              <div>
                <feather-icon icon="MoreVerticalIcon" size="20" class="text-primary">
                </feather-icon>
              </div>
            </template>
            <b-dropdown-item @click="rowAction('repurchase', data.item)">
              Recompra de título
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

      <template #cell(actions)="data">
        <a
          target="_blank"
          v-for="action in customActions" v-bind:key="action.name"
          :href="`concessoes/${data.item.id}`"
          class="d-flex align-items-center justify-content-center text-center"
          :class="{ 'mr-1': customActions.length > 1 }"
        >
          <feather-icon
            icon="SearchIcon"
            class="cursor-pointer"
            size="28"
            v-b-tooltip.hover
            :title="action.label || 'Visualizar'"
          />
        </a>

        <router-link
          v-if="showRouteName && !customActions"
          tag="span"
          :to="{
            name: showRouteName,
            params: { id: data.item.id },
          }"
          class="d-flex align-items-center justify-content-center text-center"
        >
          <feather-icon
            icon="SearchIcon"
            class="cursor-pointer"
            size="28"
            v-b-tooltip.hover
            title="Visualizar"
          />
        </router-link>
      </template>

      <template #cell(actionsRediscount)="data" v-if="showRediscountAtions">
        <div class="d-flex align-items-center" v-if="data.item.status === 'pendente' || data.item.status === 'processando'">
          <b-dropdown
            block
            variant="flat-primary"
            no-caret
          >
            <template #button-content>
              <div>
                <feather-icon icon="MoreVerticalIcon" size="20" class="text-primary">
                </feather-icon>
              </div>
            </template>
            <b-dropdown-item
              v-bind:key="'marcar-processado'+data.item.id"
              @click="rowAction('marcarProcessado', data.item)"
              v-if="data.item.status === 'pendente'"
            >
              Marcar processado
            </b-dropdown-item>
            <b-dropdown-item
              v-bind:key="'marcar-executado'+data.item.id"
              @click="rowAction('marcarExecutado', data.item)"
              v-if="data.item.status === 'processando'"
            >
              Marcar executado
            </b-dropdown-item>
            <b-dropdown-item
              v-bind:key="'marcar-rejeitado'+data.item.id"
              @click="rowAction('marcarRejeitado', data.item)"
              v-if="data.item.status === 'processando' || data.item.status === 'pendente'"
            >
              Marcar rejeitado
            </b-dropdown-item>
            <b-dropdown-item
              v-bind:key="'baixar-cnab'+data.item.id"
              @click="rowAction('BaixarCnab', data.item)"
            >
              Baixar cnab
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>

    <slot
      name="table-footer"
      :items="items"
      :showing="showing"
      :currentPage="currentPage"
      :totalRows="totalRows"
      :perPage="perPage"
    >
      <b-row class="mt-2">
        <b-col
          cols="12"
          sm="9"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <div class="d-flex align-items-center">
            <label class="mb-0 mr-1">Itens por página</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-flex flex-column mr-1"
            />
          </div>
          <b-button
            variant="flat-primary"
            class="mr-1"
            @click="xlsxExportAction"
            v-if="showExportButton"
            :disabled="selectedRows.length === 0"
          >
            Exportar para Excel <feather-icon icon="ShareIcon" size="18" />
          </b-button>
          <span class="text-muted"> {{ showing }} </span>
          <b-button
            @click="exportToExcellAction()"
            variant="flat-secondary"
            class="text-nowrap ml-1"
            v-if="showExportToExcell"
          >
            Exportar
            <img
              class="img-icon text-secondary ml-50"
              height="17"
              width="16"
              src="@/assets/images/export_icon.svg"
            />
          </b-button>
        </b-col>
        <b-col
          cols="12"
          sm="3"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            aria-controls="my-table"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </slot>

    <datatable-filters-handler-sidebar
      v-if="customFilters || $route.meta.indexObject?.filters"
      :is-task-handler-sidebar-active="showFiltersButton"
      @closeSideBar="showFiltersButton = false"
      @updateFilterValues="updateFilterValues"
    />
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BBadge,
  BTbody,
  BButton,
  BTooltip,
  BDropdown,
  VBTooltip,
  BFormCheckbox,
  BIconSearch,
  BFormGroup,
  BPagination,
  BFormInput,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DatatableFiltersHandlerSidebar from '@/views/common/crud/components/DatatableFiltersHandlerSidebar.vue'
import { truncateWithEllipsis } from '@core/comp-functions/data_visualization/string'
import { directDownload } from '@core/comp-functions/forms/cc-dropzone'
import { brazilianFormattedDate, moneyFormatDatatable } from '@core/comp-functions/data_visualization/datatable'

export default {
  name: 'Datatable',
  props: {
    noBody: {
      type: Boolean,
      default: false,
    },
    filterValues: {
      type: Array,
      default: () => [],
    },
    xlsxExportAction: {
      type: Function,
      required: false,
    },
    showExportButton: Boolean,
    serviceUrl: {
      type: String,
      required: false,
    },
    customActions: {
      type: Array,
      required: false,
    },
    customFields: {
      type: Array,
      required: false,
    },
    customLastRow: {
      type: Array,
      required: false,
    },
    customFilters: {
      type: Array,
      required: false,
    },
    showExportToExcell: {
      type: Boolean,
      default: false,
    },
    exportToExcellAction: {
      type: Function,
      required: false,
    },
    showPath: {
      type: String,
      required: false,
    },
    datatableTitleProps: {
      type: String,
      required: false
    },
    showRediscountAtions: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BRow,
    BCol,
    BCard,
    BTbody,
    BTable,
    BBadge,
    BButton,
    vSelect,
    BTooltip,
    BDropdown,
    BFormInput,
    BFormGroup,
    BPagination,
    BIconSearch,
    BDropdownItem,
    BFormCheckbox,
    DatatableFiltersHandlerSidebar,
  },
  filters: {
    moneyFormat: moneyFormatDatatable,
    dateFormat: brazilianFormattedDate,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      brazilianFormattedDate,
      moneyFormatDatatable,
      datatableFilterValues: {},
      skipCurrentPage: true,
      skipGetData: false,
      showFiltersButton: false,
      selectedRows: [],
      storeOptions: [],
      typeOptions: [],
      perPage: 10,
      perPageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: true,
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      data: {},
      items: [],
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    truncateWithEllipsis,
    toggleSelectedAllRows($event) {
      this.$refs.ccDatatable[$event === true ? 'selectAllRows' : 'clearSelected']()
    },
    onRowSelected(items) {
      this.selectedRows = items
      this.$emit('changeSelectedRows', items)
    },
    selectRow(row, value) {
      this.$refs.ccDatatable[value ? 'selectRow' : 'unselectRow'](row.index)
    },
    async getData() {
      if (this.skipGetData) {
        this.skipGetData = false
        this.getData()
        return
      }

      let params = {
        path: this.serviceUrl || this.$route.meta.indexObject?.serviceUrl,
        data: this.params,
      }

      if (this.sortBy) params.data.sort = `${this.sortBy}|${this.sortDesc ? 'desc' : 'asc'}`

      try {
        const { data } = await this.$store.dispatch('app/getIndexData', params)
        this.data = data
        this.setItems(data.data)
        this.perPage = data.per_page
        this.totalRows = data.total
        if (!this.skipCurrentPage) this.currentPage = data.current_page
        this.skipCurrentPage = true
      } catch (error) {
        console.log(error)
      }
    },
    setItems(items) {
      this.items = items
      if(this.customLastRow && this.items.length > 0) {
        let newRow = {isCustomLastRow: true}
        let cellVariants = {}

        this.fields.forEach((field, index) => {
          let cellData = this.customLastRow.find(row => row.index === index)
          newRow[field.key] = ''
          if (cellData){
            if (!!cellData.key){
              let key = cellData.key.split('.')
              let value = key.length > 1 ? this[key[0]][key[1]] : this[key[0]]

              value = `
                <span class="${cellData?._variant || ''}">
                ${!cellData.formatter ? value : cellData.formatter(value)}
                </span>
              `
              newRow[field.key] = value
            }else if(!!cellData.value){
              newRow[field.key] = cellData.value
            }
          }else{
            // pode definir classes para as celulas não utilizadas
            cellVariants[field.key] = ''
          }
        })
        newRow._cellVariants = cellVariants

        this.items.push(newRow)
      }
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 3000)
    },
    clearDatatable() {
      this.perPage = 10
      this.data = {}
      this.items = []
      this.totalRows = 0
      this.currentPage = 1
      // this.sortBy = 'created_at'
    },
    showFiltersComponent() {
      this.$emit('show-filters-component')
    },
    refresh() {
      this.clearDatatable()
      this.getData()
    },
    updateFilterValues(values) {
      this.datatableFilterValues = values
    },
    onHeadClicked(key, field, event) {
      if (field.sortable) {
        if (this.sortBy === key) {
          this.sortDesc = !this.sortDesc
          this.getData()
          return
        } else {
          this.sortBy = key
          this.sortDesc = false
          this.getData()
        }
      }
    },
    showExeptionFileImportEventImportErrors(item){
      const importErrors = item.import_errors
      if(!importErrors) return;
      let html = `<ul>${importErrors.map((e) => `<li>${e}</li>`).join('')}</ul>`
      this.$swal.fire({
        title: 'Erros de importação!',
        html: html,
        icon: 'error',
        confirmButtonText: 'Fechar',
      })
    },
    exeptionFileImportEventStatusBadge(item){
      const variant = {
        importado: 'success',
        rejeitado: 'danger',
      }[item.status]

      const status = {
        importado: 'Importado',
        rejeitado: 'Rejeitado',
      }[item.status]

      return `<span class="badge badge-light-${variant} badge-text-dark border-${variant} badge-pill">${status}</span>`
    },
    copy(item) {
      this.$copyText(item.chave_nfe).then(() => {
        setTimeout(() => this.$refs[`tooltip-${item.id}`].$emit('close'), 750)
      })

      setTimeout(() => {
        this.$refs[`tooltip-${item.id}`].$emit('close')
      }, 750)
    },
    copyInviteLink(item) {
      this.$copyText(item.url_convite).then(() => {
        setTimeout(() => this.$refs[`urlTooltip-${item.id}`].$emit('close'), 750)
      })

      setTimeout(() => {
        this.$refs[`urlTooltip-${item.id}`].$emit('close')
      }, 750)
    },
    rowAction(action, payload) {
      this.$emit('row-action', { action, payload })
    },
    valorFace(data) {
      return moneyFormatDatatable(String(parseFloat(data.updated_redeemed_amount) + parseFloat(data.value)))
    },
    directDownload,
  },
  computed: {
    showRouteName() {
      return this.$route.meta.indexObject?.showRouteName || this.showPath
    },
    enableSelectableDatatable() {
      return this.customFields.some((f) => f.key === 'select')
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
    },
    showing() {
      let start = this.currentPage === 1 ? 1 : (this.currentPage - 1) * this.perPage + 1
      let end = start === 1 ? this.perPage : start + this.perPage - 1
      if (end > this.totalRows) end = this.totalRows
      if (this.totalRows === 0) start = 0

      return `Mostrando de ${start} a ${end} de ${this.totalRows} registros`
    },
    fields() {
      let fields = this.customFields || this.$route.meta.indexObject?.fields

      if (this.customActions || this.$route.meta.indexObject?.actions)
        fields.push({ key: 'actions', label: '', sortable: false })

      return fields
    },
    params() {
      let params = {
        per_page: this.perPage,
        page: this.currentPage,
      }

      if (Object.keys(this.datatableFilterValues).length)
        params = { ...params, ...this.datatableFilterValues }

      return params
    },
    canShowFiltersButton() {
      return (
        this.$route.meta.indexObject?.filters && this.$route.meta.indexObject?.filters.length > 0
      )
    },
  },
  watch: {
    $route() {
      this.clearDatatable()
      this.getData()
    },
    perPage: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.currentPage = 1
          this.skipCurrentPage = false
        }
      },
      immediate: true,
    },
    params(newValue, oldValue) {
      if (newValue !== oldValue) this.getData()
    },
    currentPage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('changeCurrentPage', newValue)
      }
    },
  },
}
</script>

<style lang="scss">
#my-table th {
  background-color: #eff4f6;
  color: #242a32;
}

#my-table .table-warning,
#my-table .table-warning > th,
#my-table .table-warning > td {
  background-color: #f8944d12;
}

.per-page-selector {
  min-width: 80px;
}

.b-dropdown {
  button {
    padding: 0;
  }
}

.opacity-05 {
  opacity: 0.5;
}

.min-height-table {
  min-height: 195px;
}
</style>
