<template>
  <div>
    <datatable ref="operationsDatatable" :customFields="fields" :serviceUrl="serviceUrl">
      <template #table-header-actions>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-between align-items-center">
            <v-select
              v-model="selectedAssignor"
              :options="assignors"
              placeholder="Cedente"
              class="assignor-selector d-flex flex-column mr-1"
            >
            </v-select>

            <v-select
              v-model="selectedStatus"
              :options="statuses"
              placeholder="Status"
              class="assignor-selector d-flex flex-column mr-1"
            >
            </v-select>

            <flat-pickr
              v-model="data_vencimento_de"
              class="assignor-selector form-control bg-white mr-1"
              :config="config"
              placeholder="Data de vencimento"
            />
          </div>
        </div>
      </template>
    </datatable>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Datatable from '@/views/common/crud/components/Datatable'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import {
  moneyFormatDatatable,
  brazilianFormattedDate,
  situationStatusFormatter,
} from '@core/comp-functions/data_visualization/datatable'
import CcIcon from '@/@core/components/cc-icon/CcIcon.vue'

export default {
  name: 'Operations',
  components: {
    CcIcon,
    BButton,
    vSelect,
    flatPickr,
    Datatable,
  },
  data() {
    return {
      serviceUrl: '/backoffice/operacoes',
      selectedStatus: null,
      selectedAssignor: null,
      data_vencimento_de: '',
      data_vencimento_ate: '',
      assignors: [],
      fields: [
        {
          label: 'Número',
          key: 'id',
        },
        {
          label: 'Cedente',
          key: 'cedente.nome',
        },
        {
          label: 'Data de envio',
          key: 'created_at',
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Qtd. de títulos',
          key: 'quantidade_titulos',
        },
        {
          label: 'Valor de face',
          key: 'soma_valor_face',
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Valor operado',
          key: 'soma_valor_operado',
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Tipo',
          key: 'descricao_tipo_titulo',
        },
        {
          label: 'Status',
          key: 'status',
          formatter: situationStatusFormatter,
        },
        {
          label: '',
          key: 'editAction',
        },
      ],
      statuses: [
        { value: 'ativo', label: 'Ativo' },
        { value: 'inativo', label: 'Inativo' },
      ],
      config: {
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
    }
  },
  async mounted() {
    this.getAssignors()
  },
  methods: {
    async getAssignors() {
      const data = await this.$store.dispatch('admin/getAssignors')
      this.assignors = data.map((assignor) => ({
        value: assignor.id,
        label: assignor.nome,
      }))
    },
    updateFilterValues(values) {
      this.datatableFilterValues = values
    },
  },
  computed: {
    params() {
      let params = {}

      if (this.selectedStatus) params.status = this.selectedStatus.value
      if (this.selectedAssignor) params.cedente_id = this.selectedAssignor.value
      if (this.data_vencimento_de) params.data_vencimento_de = this.data_vencimento_de
      if (this.data_vencimento_ate) params.data_vencimento_ate = this.data_vencimento_ate

      return params
    },
  },
  watch: {
    params(newValue, oldValue) {
      if (newValue !== oldValue) this.$refs.operationsDatatable.updateFilterValues(newValue)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.assignor-selector {
  min-width: 200px;
}
</style>
