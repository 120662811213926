const extractNameFromS3Url = (url) => {
  if (!url) return 'arquivo não enviado';

  url = url.split('?')
  const name = url[0].split('/')

  return name[name.length - 1]
}

const downloadURI = (url) => {
  let link = document.createElement("a");
  link.target = "_blank"
  link.download = extractNameFromS3Url(url);
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

async function directDownload(url) {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = extractNameFromS3Url(url);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  } catch (error) {
    console.error('Erro ao fazer o download:', error);
  }
}

async function getFileFromS3Url(url) {

  const fileRes = await fetch(`https://cors-anywhere.herokuapp.com/${url}`);
  const blob = await fileRes.blob();

  const fileName = extractNameFromS3Url(url)

  const file = new File([blob], fileName, {
    type: blob.type,
  });

  return file;
}

export {
  extractNameFromS3Url,
  downloadURI,
  getFileFromS3Url,
  directDownload,
}