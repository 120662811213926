<template>
  <div>
    <svg
      v-if="icon == 'CustomCalc'"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3"
        y="2.24983"
        width="12"
        height="13.5"
        rx="2"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="3"
        y="2.24983"
        width="12"
        height="13.5"
        rx="2"
        stroke="currentColor"
        stroke-opacity="0.2"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="6"
        y="5.24983"
        width="6"
        height="2.25"
        rx="1"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="6"
        y="5.24983"
        width="6"
        height="2.25"
        rx="1"
        stroke="currentColor"
        stroke-opacity="0.2"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 10.4998V10.5073"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 10.4998V10.5073"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 10.4998V10.5073"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 12.7498V12.7573"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 12.7498V12.7573"
        stroke="currentColor"
        stroke-opacity="0.2"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 12.7498V12.7573"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 12.7498V12.7573"
        stroke="currentColor"
        stroke-opacity="0.2"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 12.7498V12.7573"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 12.7498V12.7573"
        stroke="currentColor"
        stroke-opacity="0.2"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      v-else-if="icon == 'CustomNotasERecibosIcon'"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 15.75V3.75C3.75 2.92157 4.42157 2.25 5.25 2.25H12.75C13.5784 2.25 14.25 2.92157 14.25 3.75V15.75L12 14.25L10.5 15.75L9 14.25L7.5 15.75L6 14.25L3.75 15.75"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 6.75C10.9142 6.75 11.25 6.41421 11.25 6C11.25 5.58579 10.9142 5.25 10.5 5.25V6.75ZM9.375 8.25V7.5V8.25ZM9.375 10.5V11.25V10.5ZM7.5 9.75C7.08579 9.75 6.75 10.0858 6.75 10.5C6.75 10.9142 7.08579 11.25 7.5 11.25V9.75ZM9.75 10.5C9.75 10.0858 9.41421 9.75 9 9.75C8.58579 9.75 8.25 10.0858 8.25 10.5H9.75ZM8.25 11.625C8.25 12.0392 8.58579 12.375 9 12.375C9.41421 12.375 9.75 12.0392 9.75 11.625H8.25ZM9.75 4.875C9.75 4.46079 9.41421 4.125 9 4.125C8.58579 4.125 8.25 4.46079 8.25 4.875H9.75ZM8.25 6C8.25 6.41421 8.58579 6.75 9 6.75C9.41421 6.75 9.75 6.41421 9.75 6H8.25ZM10.5 5.25H8.625V6.75H10.5V5.25ZM8.625 5.25C7.58947 5.25 6.75 6.08947 6.75 7.125H8.25C8.25 6.91789 8.41789 6.75 8.625 6.75V5.25ZM6.75 7.125C6.75 8.16053 7.58947 9 8.625 9V7.5C8.41789 7.5 8.25 7.33211 8.25 7.125H6.75ZM8.625 9H9.375V7.5H8.625V9ZM9.375 9C9.58211 9 9.75 9.16789 9.75 9.375H11.25C11.25 8.33947 10.4105 7.5 9.375 7.5V9ZM9.75 9.375C9.75 9.58211 9.58211 9.75 9.375 9.75V11.25C10.4105 11.25 11.25 10.4105 11.25 9.375H9.75ZM9.375 9.75H7.5V11.25H9.375V9.75ZM8.25 10.5V11.625H9.75V10.5H8.25ZM8.25 4.875V6H9.75V4.875H8.25Z"
        fill="currentColor"
      />
    </svg>

    <svg
      v-else-if="icon == 'CustomEraserIcon'"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2498 14.25H5.99983L2.99983 11.25C2.70904 10.9574 2.70904 10.485 2.99983 10.1925L10.4998 2.69246C10.7924 2.40167 11.2648 2.40167 11.5573 2.69246L15.3073 6.44246C15.5981 6.73499 15.5981 7.20743 15.3073 7.49996L8.55733 14.25"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.4999 9.225L8.7749 4.5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      v-else-if="icon == 'CustomFileCertificateIcon'"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6665 2.55005V5.88338C11.6665 6.34362 12.0396 6.71672 12.4998 6.71672H15.8332"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.1665 6.71672V4.21672C4.1665 3.29624 4.9127 2.55005 5.83317 2.55005H11.6665L15.8332 6.71672V15.8834C15.8332 16.8039 15.087 17.55 14.1665 17.55H9.99984"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="5"
        cy="11.7168"
        r="2.5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.75 14.2168L2.5 18.3835L5 17.1335L7.5 18.3835L6.25 14.2168"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      v-else-if="icon == 'CustomReportMoneyIcon'"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49984 4.2168H5.83317C4.9127 4.2168 4.1665 4.96299 4.1665 5.88346V15.8835C4.1665 16.8039 4.9127 17.5501 5.83317 17.5501H14.1665C15.087 17.5501 15.8332 16.8039 15.8332 15.8835V5.88346C15.8332 4.96299 15.087 4.2168 14.1665 4.2168H12.4998"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="7.5"
        y="2.55005"
        width="5"
        height="3.33333"
        rx="1.66667"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6668 9.2168H9.5835C8.89314 9.2168 8.3335 9.77644 8.3335 10.4668C8.3335 11.1572 8.89314 11.7168 9.5835 11.7168H10.4168C11.1072 11.7168 11.6668 12.2764 11.6668 12.9668C11.6668 13.6572 11.1072 14.2168 10.4168 14.2168H8.3335"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.7502 14.2166C10.7502 13.8024 10.4144 13.4666 10.0002 13.4666C9.58595 13.4666 9.25016 13.8024 9.25016 14.2166H10.7502ZM9.25016 15.05C9.25016 15.4642 9.58595 15.8 10.0002 15.8C10.4144 15.8 10.7502 15.4642 10.7502 15.05H9.25016ZM10.7502 8.3833C10.7502 7.96909 10.4144 7.6333 10.0002 7.6333C9.58595 7.6333 9.25016 7.96909 9.25016 8.3833H10.7502ZM9.25016 9.21663C9.25016 9.63085 9.58595 9.96663 10.0002 9.96663C10.4144 9.96663 10.7502 9.63085 10.7502 9.21663H9.25016ZM9.25016 14.2166V15.05H10.7502V14.2166H9.25016ZM9.25016 8.3833V9.21663H10.7502V8.3833H9.25016Z"
        fill="white"
      />
    </svg>

    <svg
      v-else-if="icon == 'CustomReturnFilesIcon'"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00586 17.5501V14.2168C4.00586 12.8361 5.12515 11.7168 6.50586 11.7168H10.6725"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.17285 14.2168L10.6729 11.7168L8.17285 9.2168"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.3398 2.55005V5.88338C12.3398 6.34362 12.7129 6.71672 13.1732 6.71672H16.5065"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.83984 9.21672V4.21672C4.83984 3.29624 5.58604 2.55005 6.50651 2.55005H12.3398L16.5065 6.71672V15.8834C16.5065 16.8039 15.7603 17.55 14.8398 17.55H6.92318"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CCIcon',
  props: {
    icon: {
      required: true,
      type: String,
    },
  },
}
</script>
