const truncateWithEllipsis = (str, style = 'midle', size = 5) => {
  if (!str) return ''
  if (str.length <= 10) return str

  const firstPart = str.substring(0, size)
  const lastPart = str.substring(str.length - 8, str.length)

  if (style === 'end') return `${firstPart}...`

  return `${firstPart}...${lastPart}`
}

export { truncateWithEllipsis }
